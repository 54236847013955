<template>
  <div>
    <div>
      <van-cell-group inset>
        <!-- 栏目名称 -->
        <van-field
          v-model="username"
          required
          label="栏目名称"
          placeholder="请输入栏目名称"
        />
        <!-- 父级栏目 -->
        <van-field
          v-model="parentText"
          is-link
          readonly
          name="picker"
          label="父级栏目"
          placeholder="请选择父级栏目"
          @click="showParent = true"
        />
        <!-- 栏目排序 -->
        <van-field
          v-model="OrderText"
          required
          label="栏目排序"
          placeholder="请输入栏目排序"
        />
        <!-- 使用范围 -->
        <van-field
          v-model="result"
          is-link
          readonly
          required
          name="picker"
          label="使用范围"
          placeholder="点击选择使用范围"
          @click="showPicker = true"
        />
        <!-- 导航显示 -->
        <van-field
          v-model="resultSort"
          is-link
          readonly
          required
          name="picker"
          label="导航显示"
          placeholder="点击选择导航显示"
          @click="showNavigation = true"
        />
        <!-- 栏目类型 -->
        <van-field
          v-model="resultType"
          is-link
          readonly
          required
          name="picker"
          label="栏目类型"
          placeholder="点击选择栏目类型"
          @click="showType = true"
        />
      </van-cell-group>
      <div style="width: 90%; margin: 0 auto">
        <van-button
          @click="save"
          style="margin-top: 0.4rem"
          type="primary"
          size="large"
        >
          保存
        </van-button>
      </div>
    </div>
    <!-- 弹出使用范围 -->
    <!-- 使用范围 -->
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showType = false"
        :columns-field-names="columnsstyle"
      />
    </van-popup>
    <!-- 导航显示 -->
    <van-popup v-model:show="showNavigation" position="bottom">
      <van-picker
        :columns="Iscolumns"
        @confirm="onConfirmIs"
        @cancel="showType = false"
        :columns-field-names="columnsstyle"
      />
    </van-popup>
    <!-- 栏目列表 -->
    <van-popup v-model:show="showType" position="bottom">
      <van-picker
        :columns="columnsList"
        @confirm="onConfirmType"
        @cancel="showType = false"
        :columns-field-names="columnsstyle"
      />
    </van-popup>
    <!-- 父级栏目 -->
    <van-popup
      v-model:show="showParent"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div style="width: 95%; margin: 0 auto">
        <div class="flx" style="height: 40px; line-height: 40px">
          <div
            style="color: var(--van-picker-cancel-action-color)"
            @click="showParent = false"
          >
            取消
          </div>
          <div
            style="color: var(--van-picker-confirm-action-color)"
            @click="affirm"
          >
            确认
          </div>
        </div>
        <div style="margin-top: 0.2rem">
          <van-radio-group
            v-for="(item, index) in ArrayList"
            :key="index"
            v-model="checked"
          >
            <van-radio :name="item.id" @click="Multiple(item)">
              {{ item.navName }}
            </van-radio>
          </van-radio-group>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
  import {
    ref,
    getCurrentInstance,
    onMounted,
    onUpdated,
    provide,
    reactive,
    watch,
    toRefs,
  } from "vue";
  import { Toast } from "vant";
  export default {
    setup() {
      const showPicker = ref(false);
      const { proxy } = getCurrentInstance();

      const reactiveParams = reactive({
        checked: "",
        username: "",
        OrderText: "",
        result: "",
        showNavigation: false,
        showType: false,
        showParent: false,
        columns: JSON.parse(sessionStorage.getItem("configuration")).useRange,
        Iscolumns: JSON.parse(sessionStorage.getItem("configuration"))
          .navigationDisplay,
        columnsList: JSON.parse(sessionStorage.getItem("configuration"))
          .columnType,
        columnsstyle: { text: "name" },
        resultSort: "",
        resultSortValue: "",
        resultType: "",
        parentText: "",
        ArrayList: JSON.parse(proxy.$route.params.message),
        resultValue: "",
        resultTypeValue: "",
        MultipleValue: "",
      });
      // 导航栏显示确认
      const onConfirm = (e) => {
        console.log(e);
        reactiveParams.result = e.name;
        reactiveParams.resultValue = e.value;
        reactiveParams.showPicker = false;
      };
      // 选择显示
      const Multiple = (e) => {
        console.log(e);
        reactiveParams.MultipleValue = e;
      };
      // 使用范围确认方法
      const onConfirmIs = (e) => {
        console.log(e);
        reactiveParams.resultSort = e.name;
        reactiveParams.resultSortValue = e.value;
        reactiveParams.showNavigation = false;
      };
      // 类型确认方法
      const onConfirmType = (e) => {
        console.log(e);
        reactiveParams.resultType = e.name;
        reactiveParams.resultTypeValue = e.value;
        reactiveParams.showType = false;
      };
      //  父级栏目确认
      const affirm = (e) => {
        console.log(reactiveParams.checked);
        reactiveParams.parentText = reactiveParams.MultipleValue.navName;
        reactiveParams.showParent = false;
      };
      // 保存方法
      const save = async (e) => {
        if (proxy.$route.params.add) {
          let list = await proxy.$Api.put(proxy.$generalApi.putSysNavigator, {
            linkUrl: "",
            navIcon: "",
            navName: reactiveParams.username,
            navType: reactiveParams.resultTypeValue,
            parentId: reactiveParams.checked,
            sort: reactiveParams.OrderText,
            status: reactiveParams.resultSortValue,
            useScope: reactiveParams.resultValue,
          });
          if (list.data.code == 200) {
            Toast(list.data.msg);
          }
        } else {
          let list = await proxy.$Api.post(proxy.$generalApi.putSysNavigator, {
            linkUrl: "",
            navIcon: "",
            navName: reactiveParams.username,
            navType: reactiveParams.resultTypeValue,
            parentId: reactiveParams.checked,
            sort: reactiveParams.OrderText,
            status: reactiveParams.resultSortValue,
            useScope: reactiveParams.resultValue,
          });
          if (list.data.code == 200) {
            Toast(list.data.msg);
          }
        }
      };
      return {
        ...toRefs(reactiveParams),
        Multiple,
        showPicker,
        onConfirm,
        onConfirmIs,
        onConfirmType,
        save,
        affirm,
      };
    },
  };
</script>
<style scoped></style>
